import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import './Poliquizz.css';
import  SideBar from './SideBar.js';
import {GetData}  from './Parser.js'
import $ from 'jquery';


var score = 1;
var name="";
var dict = {
  "ZEM": 0,
};
 var names = {
   "ZEM": "Zemmour",
 };


function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function dataQuestions(){

  GetData('data/bdd.csv').then(
   function(value) {showMe(value)},
   function(error) {return error}

 );
}

function dataNamesPoliQuizz(){
  GetData('data/pres.csv').then(
   function(value) {storeNames(value)},
   function(error) {return error}

 );
}
function storeNames(value){
  for(var i =0; i < 9; i++ )
    names[value.data[i][0]] = value.data[i][1];

    console.log(names);
}

 function showMe(some){
   var i = getRandomInt(670);
   document.getElementById('Question').innerHTML = some.data[i][1];
   name = some.data[i][0];
 }


function NextQuestion(){
  score++;
  document.getElementsByClassName('Score')[0].innerHTML = "Question : " + score + "/20";
  if(score < 20)
    refresh();
  else
    endPanel();

}

function clicked(a,b){
  StoreScore(a);
  NextQuestion();
}

function refresh(){
  dataQuestions();
}
function replay(){
  score = 1;
  dict = {
    "ZEM": 0,
  };

  document.getElementsByClassName('Score')[0].innerHTML = "Question : " + score + "/20";
  $('#questionPanel').show();
  $('#QuestionNumber').show();
  $('#endPanel').hide();
    refresh();
}

function StoreScore(a){
  dict[name] = dict[name] != null ? dict[name]+a : a;

/* const d = new Date();
 d.setTime(d.getTime() + (1*24*60*60*1000));
 let expires = "expires="+ d.toUTCString();
 document.cookie = name + "=" + dict[name] + ";" + expires + ";path=/";*/

}

function endPanel(){

  var items = Object.keys(dict).map(function(key) {
    return [key, dict[key]];
  });
  items.sort(function(first, second) {
    return second[1] - first[1];
  });
  var a = new String(items[0][0]);
  var b = new String(items[1][0]);
  var c = new String(items[2][0]);
  var d = new String(items[3][0]);

  document.getElementById('best').innerHTML = names[a];
  var color = "linear-gradient(90deg, rgba(58,180,88,1) 0%, rgba(40, 44, 52, 1)" + dict[a]*5 + "%, rgba(40, 44, 52, 1) " + dict[a]*5 +"%, rgba(40, 44, 52, 1) 100%)"
  console.log(color);
    $('#best').attr('style', "background-image: " + color + "!important");

  document.getElementById('nice').innerHTML = names[b] != undefined ? names[b] : "No one";
  var color = "linear-gradient(90deg, rgba(142,180,58,1) 0%, rgba(40, 44, 52, 1)" + dict[b]*5 + "%, rgba(40, 44, 52, 1) " + dict[b]*5 +"%, rgba(40, 44, 52, 1) 100%)"
    $('#nice').attr('style', "background-image: " + color + "!important");

  document.getElementById('cool').innerHTML = names[c] != undefined ? names[c] : "No one";
  var color = "linear-gradient(90deg, rgba(180,152,58,1) 0%, rgba(40, 44, 52, 1)" + dict[c]*5 + "%, rgba(40, 44, 52, 1) " + dict[c]*5 +"%, rgba(40, 44, 52, 1) 100%)"
    $('#cool').attr('style', "background-image: " + color + "!important");

  document.getElementById('okay').innerHTML = names[d] != undefined ? names[d] : "No one";
  var color = "linear-gradient(90deg,  rgba(180,84,58,1) 0%, rgba(40, 44, 52, 1)" + dict[d]*5 + "%, rgba(40, 44, 52, 1) " + dict[d]*5 +"%, rgba(40, 44, 52, 1) 100%)"
    $('#okay').attr('style', "background-image: " + color + "!important");

end();
}

function end(){

  $('#questionPanel').hide();
  $('#QuestionNumber').hide();
  $('#endPanel').show();

}

$(document).ready(function(){
    $('#questionPanel').show();
    $('#QuestionNumber').show();

    $('#endPanel').hide();
});

dataNamesPoliQuizz();

const PoliQuizz = () => {

  return (
refresh(),
    <div className="App">

    <SideBar></SideBar>

      <header className="App-header">
        <h1 > PoliQuizz</h1>
      </header>
  <section id="questionPanel">
        <div className="Title">
          <h2 id="Question"> "Instaurer une taxe carbonne à l'entrée de l'UE" </h2>
        </div>
        <div className="Answer-body">
          <h3 className="Answer-quizz" onClick={(event) => clicked(5, event)} > Super   </h3>
          <h3 className="Answer-quizz" onClick={(event) => clicked(2, event)} > Cool  </h3>
          <h3 className="Answer-quizz" onClick={(event) => clicked(0, event)} > Bof  </h3>
          <h3 className="Answer-quizz" onClick={(event) => clicked(-2, event)} > Beurk  </h3>
        </div>
    </section>
      <h4 id="QuestionNumber" className="Score" > Question : 1/20 </h4>

      <section id="endPanel">
        <div className="Title">
          <h2 id="Question"> Voilà de qui tu es le plus proche:  </h2>
        </div>
        <div className="Answer-body">
          <h3 className="endAnswer" id="best"  > Pecresse   </h3>
          <h3 className="endAnswer" id="nice"  > Zemmour  </h3>
          <h3 className="endAnswer" id="cool"  > Macron  </h3>
          <h3 className="endAnswer" id="okay" > Mélenchon  </h3>
        </div>
        <div >
          <h3 onClick={(event) => replay()} className="Replay">   Recommencer  </h3>
        </div>
      </section>
      <footer className="BottomText">
        <p> <a href="https://www.twitter.com/ElouanAQ1" target="_blank"> Vous pouvez me suivre ici.</a></p>
        <p> <a href="https://www.paypal.com/fr/webapps/mpp/home" target="_blank"> Ou m'offrir un café ☕</a></p>
      </footer>
    </div>
  );
};

export default PoliQuizz;
